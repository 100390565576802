


























import { Component, Vue } from 'vue-property-decorator';
import OptionsCard from '@/vue-app/components/allianz-dashboard/OptionsCard.vue';
import InformationViewModel from '../../view-models/allianz-dashboard/information-view-model';
import Beneficiaries from '@/vue-app/components/allianz-dashboard/allianz-actions/BeneficiariesDialog.vue';

@Component({
  name: 'Information',
  components: {
    OptionsCard,
    Beneficiaries,
    RecurringDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositDialog.vue'),
  },
})
export default class Information extends Vue {
  information_view_model = Vue.observable(new InformationViewModel());

  endProcess() {
    this.information_view_model.show_edit_recurring_contribution_dialog = false;
  }
}
