import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InformationViewModel {
  i18n_namespace = 'components.allianz-dashboard.information';

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  information_list = [
    {
      title: this.translate('allianz_portal'),
      content: this.translate('allianz_portal_description'),
      icon: 'mdi-paperclip',
      action: () => {
        window.open('https://www.allianz.com.mx/', '_blank');
      },
    },
    {
      title: this.translate('beneficiaries'),
      content: this.translate('beneficiaries_description'),
      icon: 'mdi-account',
      action: () => {
        this.show_beneficiaries_dialog = true;
      },
    },
    {
      title: this.translate('edit_recurring_contributions'),
      content: this.translate('edit_recurring_contributions_description'),
      icon: 'mdi-pencil',
      action: () => {
        this.show_edit_recurring_contribution_dialog = true;
      },
    },
  ];

  show_beneficiaries_dialog = false;

  show_edit_recurring_contribution_dialog = false;
}
